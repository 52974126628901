import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import GatsbyLink from 'gatsby-link'

import { css } from '@emotion/core'

import { Section, Hero, Container, TitleCaption, SEO } from '../webhart-base'
import { gradients, colors, packages } from '../webhart-base/utils/style'

import Layout from '../components/Layout'
import WorkList, { TagLabels } from '../components/WorkList'
import ScrollDown from '../components/ScrollDown'

class PortfolioPage extends Component {
  constructor(props) {
    super(props)

    let hash = this.props.location.hash
    if (hash == '' || hash == undefined || hash == '#all') {
      hash = undefined
    } else {
      hash = hash.replace('#', '')
    }

    this.state = { hash: hash }
  }

  render() {
    const { data } = this.props

    let hash = this.props.location.hash
    if (hash == '' || hash == undefined || hash == '#all') {
      hash = undefined
    } else {
      hash = hash.replace('#', '')
    }

    if (this.state.hash != hash) {
      hash = this.state.hash
    }

    return (
      <Layout>
        <SEO title="my work" />
        <Hero size={33} background={gradients.darkBlue} color={colors.orange}>
          <h1>my work</h1>
          {/* <TitleCaption color="white">
            Aliqua minim officia eu tempor amet commodo minim minim minim cillum
            adipisicing consequat.
          </TitleCaption> */}
        </Hero>
        <Section>
          <Container width="wide">
            <span>filter</span>
            <TagLabels
              css={css`
                justify-content: center;
                flex-direction: row-reverse;
              `}
            >
              {data.portfolio.packages.map((pack, i) => (
                <React.Fragment key={i}>
                  <GatsbyLink
                    css={css`
                      &&& {
                        background: ${colors.lightBlue};
                        line-height: 1.6;
                      }
                    `}
                    to={`/work-together#${pack.fieldValue}`}
                  >
                    {packages[pack.fieldValue]}: {pack.totalCount}
                  </GatsbyLink>
                </React.Fragment>
              ))}
            </TagLabels>
            <TagLabels
              css={css`
                justify-content: center;
              `}
            >
              <button
                key="tag-all"
                // href="#all"
                onClick={() => this.setState({ hash: undefined })}
              >
                all: {data.portfolio.edges.length}
              </button>
              {data.portfolio.tags.map((tag, i) => (
                <button
                  key={i}
                  onClick={() => this.setState({ hash: tag.fieldValue })}
                >
                  {tag.fieldValue}: {tag.totalCount}
                </button>
              ))}
            </TagLabels>
            <br />
            <WorkList
              items={data.portfolio.edges}
              tag={hash}
              tagClickHandler={tag => this.setState({ hash: tag })}
            />
          </Container>
          <ScrollDown />
        </Section>
      </Layout>
    )
  }
}

export default PortfolioPage

export const PortfolioPageQuery = graphql`
  query PortfolioPageQuery {
    portfolio: allMarkdownRemark(
      filter: {
        frontmatter: {
          #featured: { eq: true },
          #draft: { ne: true }
          templateKey: { eq: "site" }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      tags: group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      packages: group(field: frontmatter___package) {
        fieldValue
        totalCount
      }
      edges {
        node {
          ...WorkListFragment
        }
      }
    }
  }
`
